
/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48I2IzYzYxOCIsIj9lcjwjY2JkYTdjIiwiO2VyPCM3Nzg0MTB$LCIlPmBePCM2YjZiNmIiLCI~ZXI8I2M3YzdjNyIsIjtlcjwjNDQ0NDQ0fiwid2Fybj5gXjwjOWUyOTIzIiwiP2VyPCNmODgwNzgiLCI7ZXI8I2UzMWIwY34sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEzfQ==
*/

@use '@angular/material' as mat;

@import './variables';

@include mat.core();

$claas-font-family: 'CLAASCultivaWeb';

$claas-typography: mat.define-typography-config(
    $headline-1: mat.define-typography-level(   $font-family: $font-name, 
                                                $font-weight: 400,
                                                $line-height: 125%,
                                                $font-size: 32px,
                                                $letter-spacing: 0.0075em),
    $headline-2: mat.define-typography-level(   $font-family: $font-name, 
                                                $font-weight: 400,
                                                $line-height: 128.57%,
                                                $font-size: 28px,
                                                $letter-spacing: 0.0089em),
    $headline-3: mat.define-typography-level(   $font-family: $font-name, 
                                                $font-weight: 400,
                                                $line-height: 133.3%,
                                                $font-size: 24px),
    $headline-4: mat.define-typography-level(   $font-family: $font-name, 
                                                $font-weight: 400,
                                                $line-height: 160%,
                                                $font-size: 20px),
    $headline-5: mat.define-typography-level(   $font-family: $font-name, 
                                                $font-weight: 500,
                                                $line-height: 160%,
                                                $font-size: 20px),
    $headline-6: mat.define-typography-level(   $font-family: $font-name, 
                                                $font-weight: 400,
                                                $line-height: 144.4%,
                                                $letter-spacing: 0.0084em,
                                                $font-size: 18px),
    $subtitle-1: mat.define-typography-level(   $font-family: $font-name, 
                                                $font-weight: 500,
                                                $font-size: 16px,
                                                $line-height: 175%,
                                                $letter-spacing: 0.0094em),
    $subtitle-2: mat.define-typography-level(   $font-family: $font-name, 
                                                $font-weight: 500,
                                                $font-size: 14px,
                                                $line-height: 157%,
                                                $letter-spacing: 0.0071em),
    $body-1: mat.define-typography-level(       $font-family: $font-name, 
                                                $font-weight: 400,
                                                $font-size: 16px,
                                                $line-height: 150%,
                                                $letter-spacing: 0.0094em),
    $body-2: mat.define-typography-level(       $font-family: $font-name, 
                                                $font-weight: 400,
                                                $font-size: 14px,
                                                $line-height: 143%,
                                                $letter-spacing: 0.0107em),
    $caption: mat.define-typography-level(      $font-family: $font-name, 
                                                $font-weight: 400,
                                                $font-size: 12px,
                                                $line-height: 166%,
                                                $letter-spacing: 0.0333em),
    $overline: mat.define-typography-level(     $font-family: $font-name, 
                                                $font-weight: 400,
                                                $font-size: 12px,
                                                $line-height: 266%,
                                                $letter-spacing: 0.0125em),

  );


  body {
    --primary-color: #b3c618;
    --primary-lighter-color: #cbda7c;
    --primary-darker-color: #778410;
    --text-primary-color: #{$dark-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$light-primary-text};
  }   
  $mat-primary: (
    main: #b3c618,
    lighter: #cbda7c,
    darker: #778410,
    200: #b3c618, // For slide toggle,
    contrast : (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    )
  );
  $theme-primary: mat.define-palette($mat-primary, main, lighter, darker);
  
  
  body {
    --accent-color: #6b6b6b;
    --accent-lighter-color: #c7c7c7;
    --accent-darker-color: #444444;
    --text-accent-color: #{$light-primary-text};
    --text-accent-lighter-color: #{$dark-primary-text};
    --text-accent-darker-color: #{$light-primary-text};
  }   
  $mat-accent: (
    main: #6b6b6b,
    lighter: #c7c7c7,
    darker: #444444,
    200: #6b6b6b, // For slide toggle,
    contrast : (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    )
  );
  $theme-accent: mat.define-palette($mat-accent, main, lighter, darker);
  
  
  body {
    --warn-color: #9e2923;
    --warn-lighter-color: #f88078;
    --warn-darker-color: #e31b0c;
    --text-warn-color: #{$light-primary-text};
    --text-warn-lighter-color: #{$dark-primary-text};
    --text-warn-darker-color: #{$light-primary-text};
  }   
  $mat-warn: (
    main: #9e2923,
    lighter: #f88078,
    darker: #e31b0c,
    200: #9e2923, // For slide toggle,
    contrast : (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    )
  );
  $theme-warn: mat.define-palette($mat-warn, main, lighter, darker);



  $claas-theme: mat.define-light-theme((
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn
    ),
    typography: $claas-typography,
   ));


   @include mat.all-component-themes($claas-theme);


   